import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-body d-flex flex-column p-0" }
const _hoisted_2 = { class: "d-flex flex-stack flex-grow-1 card-p" }
const _hoisted_3 = { class: "d-flex flex-column me-2" }
const _hoisted_4 = {
  href: "#",
  class: "text-dark text-hover-primary fw-bolder fs-3"
}
const _hoisted_5 = { class: "text-muted fw-bold mt-1" }
const _hoisted_6 = { class: "symbol symbol-50px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createBlock("div", {
    class: [_ctx.widgetClasses, "card"]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("a", _hoisted_4, _toDisplayString(_ctx.title), 1),
          _createVNode("span", _hoisted_5, _toDisplayString(_ctx.description), 1)
        ]),
        _createVNode("span", _hoisted_6, [
          _createVNode("span", {
            class: [`bg-light-${_ctx.color} text-${_ctx.color}`, "symbol-label fs-5 fw-bolder"]
          }, _toDisplayString(_ctx.change), 3)
        ])
      ]),
      _createVNode(_component_apexchart, {
        class: "statistics-widget-3-chart card-rounded-bottom",
        options: _ctx.chartOptions,
        series: _ctx.series,
        height: _ctx.height,
        type: "area"
      }, null, 8, ["options", "series", "height"])
    ])
  ], 2))
}